
import { ref, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import {study_getContent} from '@axios/index'
import { ElLoading } from 'element-plus';
export default defineComponent({
  name: "news",
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let info = ref({})
    let pageRef = ref(null)
    async function getDetail(){
      const loading = ElLoading.service({
        target:pageRef.value,
        text:'正在获取信息...'
      })
      const news = await study_getContent({})
      const newInfo = news.find(item => item.id == route.query.id)
      info.value = {
        ...newInfo,
        date:moment(newInfo.created_at).format('YYYY年MM月DD日')
      }
      loading.close()
    }
    onMounted(() => {
      getDetail()
    })
    return {
      info,
      pageRef
    };
  },
});
