<template>
  <div class="newsDetailPage" ref="pageRef">
    <h2 class="title">{{info.title}}</h2>
    <div class="desc">发布人:豫建中心 发布时间:{{info.date}}</div>
    <el-divider></el-divider>
    <div class="content" v-html="info.content"></div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import {study_getContent} from '@axios/index'
import { ElLoading } from 'element-plus';
export default defineComponent({
  name: "news",
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let info = ref({})
    let pageRef = ref(null)
    async function getDetail(){
      const loading = ElLoading.service({
        target:pageRef.value,
        text:'正在获取信息...'
      })
      const news = await study_getContent({})
      const newInfo = news.find(item => item.id == route.query.id)
      info.value = {
        ...newInfo,
        date:moment(newInfo.created_at).format('YYYY年MM月DD日')
      }
      loading.close()
    }
    onMounted(() => {
      getDetail()
    })
    return {
      info,
      pageRef
    };
  },
});
</script>
<style lang="less" scoped>
.newsDetailPage {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .title {
    text-align: center;
    padding: 5px;
  }
  .desc {
    color: #909399;
    text-align: center;
  }
  .content {
    padding: 5px;
    /deep/ img {
      max-width: 100%;
      height: unset;
      object-fit: contain !important;
    }
  }
  .block {
    display: block;
    text-align: right;
  }
}
@media (max-width: 600px) {
  .newsDetailPage {
    width: 100vw;
    /deep/ img {
      max-width: 100%;
      height: unset;
      object-fit: contain !important;
    }
  }
}
</style>